import styles from "./styles.module.css";
import Image from "@/shared/components/Image/new";

const LoMasVisto = (props) => {
    const CLIPPING = "3_2_300x200";
    const { title = "LO MÁS VISTO" } = props;
    
    let mainContent = !props.content ? [] : [...props.content];
    const highlightedContent = mainContent.length > 0 ? mainContent[0] : [];
    const content = mainContent;

	const getElementImagesBuckets = (element) =>{
		return  {
			images: [
			  element.images[0] &&
				element.images[0].value && {
				  clippings: element.images[0].value.clippings || [],
				  title: element.images[0].value.title || "",
				  _id: element.images[0]._id,
				},
			  ],
		  };
	}
    
    return (
        <>
        {title && <h3 className={`${styles["content-right-title"]}`}>{title}</h3>}
        <div className={`${styles["content-right-info"]}`}>
            {highlightedContent && 
                <a className={`${styles["content-right-info-item"]}`} href={highlightedContent.url}>
                    <Image 
                        element={highlightedContent} 
                        clipping={CLIPPING} 
                        srcSet={{
                            tablet: "3_2_630x420"
                        }}
                        width={300}
                        height={200}
                        className={styles['img']}
                        />
                </a>
            }
            <div className={`${styles["content-right-list"]}`}>
                {content && content.map((element, id) => {
                    const { url, title } = element;
                    return (
                        <a href={url} className={`${styles["content-right-info-item"]}`} key={id}>
                            <div className={`${styles["content-right-container-number"]}`}>
                                <h2>{id + 1}</h2>
                            </div>
                            <p>{title}</p>
                        </a>
                    );
                })}
            </div>
        </div>
    </>
    );
};
export default LoMasVisto;
